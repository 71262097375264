<template>
  <div>
    <div
      :class="{ 'pt-8': !$h.hasRoles(['CLIENT']), 'pt-7': $h.hasRoles(['CLIENT']) }"
      class="container-fluid pb-5"
    >
      <el-collapse class="expand-main mb-4 shadow">
        <el-collapse-item class="expand-item">
          <template #title>
            <div class="row pl-3">
              <div class="col-12 d-flex align-items-center">
                <i class="fas fa-search"></i>
                <h3 class="mb-0 ml-2">{{ $t.translate("LBL_SEARCH") }}</h3>
              </div>
            </div>
          </template>
          <card shadow type="secondary" class="">
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.CompanyOrBranch"
                    :label="$t.translate('LBL_APPLICANT')"
                    input-classes="form-control-alternative"
                  />
                </div>
                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.CertificateNo"
                    :label="$t.translate('FRM_CERT_STAT_NO')"
                    input-classes="form-control-alternative"
                  />
                </div>
              </div>
              <div class="row mt-1 mb-2">
                <div class="col-lg-6">
                  <el-button type="primary" @click="filterAction">
                    {{ $t.translate("BTN_SEARCH") }}
                  </el-button>
                  <el-button type="primary" class="ml-3" @click="resetParams">
                    {{ $t.translate("BTN_RESET") }}
                  </el-button>
                </div>
              </div>
            </div>
          </card>
        </el-collapse-item>
      </el-collapse>

      <div
        class="d-flex justify-content-end"
        v-if="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT', 'SCHEDULING'])"
      >
        <el-button type="default" class="mb-3" @click="() => exportCert($refs)">
          {{ $t.translate("BTN_EXPORT") }}
        </el-button>
      </div>

      <div class="shadow bg-white p-around">
        <Table
          url="/application/certificate/list"
          :query="{ ...Filter }"
          ref="table"
          :default-sort="{ prop: Filter.SortBy, order: Filter.SortOrder }"
          @sort-change="sortChange"
          :viewData="handleEdit"
          class="gen-table-bd"
          height="63vh"
        >
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="CertificateNo"
            :label="$t.translate('FRM_CERT_STAT_NO')"
            min-width="150px"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ scope.row.CertificateNo }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="ServiceName"
            :label="$t.translate('FRM_ASSESSMENT_SERVICE_NAME')"
            width="240px"
          >
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.table.currentSelection(arr, 'AssessmentServiceId')
                "
                :hasSelection="true"
                :fieldName="$t.translate('FRM_ASSESSMENT_SERVICE_NAME')"
                url="/assessment/list"
                fieldKey="AssessmentServiceId"
                fieldLabel="ServiceName"
                :fieldLang="true"
              />
            </template>

            <template #default="scope">
              {{ $h.formatName(scope.row, "ServiceName") }}
              <span v-if="$h.formatName(scope.row, 'ServiceNameExtra', true)">
                - {{ $h.formatName(scope.row, "ServiceNameExtra") }}</span
              >
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="Type"
            :label="$t.translate('FRM_TYPE_OF_DELIVERABLE')"
            width="140px"
          >
            <template #header>
              <TableFilter
                :handleSelection="(arr) => $refs.table.currentSelection(arr, 'Type')"
                :hasSelection="true"
                :fieldName="$t.translate('FRM_TYPE_OF_DELIVERABLE')"
                url="envconf/ServiceTypeOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              {{ $h.mapLabel(scope.row.Type, ServiceTypeOpts) }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="Applicant"
            :label="$t.translate('LBL_APPLICANT')"
            min-width="200px"
          >
            <template #default="scope">
              {{ $h.formatOrgBranch(scope.row) }}
            </template>
          </el-table-column>

          <!-- Hide this because this field is deleted in application info as well -->
          <!--<el-table-column
            :label="$t.translate('LBL_MANAGEMENT_REPRESENTATIVE')"
            width="240"
          >
            <template #default="scope">
              {{ scope.row.Representative }}
            </template>
          </el-table-column>-->

          <el-table-column
            :label="$t.translate('LBL_CREATED_DATE')"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="LastCreated"
            width="140px"
          >
            <template #default="scope">
              {{ $h.formatDateTime(scope.row.LastCreated) }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="ExpiryDateTime"
            :label="$t.translate('TH_EXPIRY_DATE')"
            width="140px"
          >
            <template #default="scope">
              {{ $h.formatDateTime(scope.row.ExpiryDateTime) }}
            </template>
          </el-table-column>

          <el-table-column
            :fixed="$h.isSmall() ? false : 'right'"
            :label="$t.translate('LBL_DISCLOSURE')"
            width="108px"
            v-if="!$h.hasRoles(['CLIENT'])"
          >
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.table.currentSelection(arr, 'IsPublished')
                "
                :hasSelection="true"
                :fieldName="$t.translate('LBL_DISCLOSURE')"
                url="envconf/DisclosureOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              <el-tag
                :type="scope.row.IsPublished ? 'success' : 'danger'"
                effect="dark"
                disable-transitions
                >{{
                  scope.row.IsPublished ? $t.translate("LBL_ON") : $t.translate("LBL_OFF")
                }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            :fixed="$h.isSmall() ? false : 'right'"
            :label="$t.translate('LBL_STATUS')"
            width="110px"
          >
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.table.currentSelection(arr, 'CertificateStatusCode')
                "
                :hasSelection="true"
                :fieldName="$t.translate('LBL_STATUS')"
                url="envconf/CertificateStatusOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              <el-tag
                :type="$configs.certificateStatusStyle[scope.row.CertificateStatusCode]"
                effect="dark"
                disable-transitions
                >{{ $t.translate(scope.row.CertificateStatusName) }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            :label="$t.translate('LBL_ACTION')"
            width="70"
            class-name="pl-1 action-col"
          >
            <template #default="scope">
              <el-dropdown
                v-if="$f.statusCertificateOptions(scope.row).length"
                trigger="click"
                :hide-on-click="true"
                @command="(comd) => $f.statusCertificate(scope.row, comd, false, $refs)"
              >
                <el-button type="primary" size="mini">
                  <i class="el-icon-arrow-down"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <template
                      v-for="item in $f.statusCertificateOptions(scope.row)"
                      :key="item.command"
                    >
                      <el-dropdown-item :command="item.command">{{
                        $t.translate(item.label)
                      }}</el-dropdown-item>
                    </template>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

              <span v-else>---</span>
            </template>
          </el-table-column>
        </Table>
      </div>
    </div>
  </div>

  <ApplicationModal ref="select" />
  <ExportModal :types="['PDF', 'EXCEL']" ref="export" />
</template>
<script>
import ApplicationModal from "@/components/ApplicationModal";
import ExportModal from "@/components/ExportModal";
import TableFilter from "@/components/TableFilter.vue";
import Table from "@/components/Table.vue";
import { useRoute } from "vue-router";
import { reactive, onMounted, onUpdated, ref } from "vue";
import { router } from "@/utils/router";
import _ from "lodash";
import { get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { store } from "@/store";
import { useMq } from "vue3-mq";

export default {
  components: { Table, TableFilter, ExportModal, ApplicationModal },
  setup() {
    const mq = useMq();
    const route = useRoute();
    let table = ref("table");
    let loaded = ref(false);

    let fieldsToSkip = ["SortBy", "SortOrder", "CertificateStatusCode"];

    let Filter = reactive({
      CompanyOrBranch: "", // from comments, this field would search both Company name and Branch name
      CertificateNo: "",
      CertificateStatusCode: route?.query?.status || "",

      SortBy: "LastCreated",
      SortOrder: "descending",
    });

    let { ServiceTypeOpts } = store.getters["config/getData"]?.envConf;

    let StatusOpts = ref([
      { value: "PUBLISHED", label: "LBL_PUBLISHED" },
      { value: "ACTIVE", label: "LBL_ACTIVE" },
      { value: "SUSPENDED", label: "LBL_SUSPENDED" },
      { value: "TERMINATED", label: "LBL_TERMINATED" },
    ]);

    onMounted(() => {
      if (route?.query?.status && route?.query?.status != "ALL") {
        Filter.CertificateStatusCode = route.query.status;
      } else {
        Filter.CertificateStatusCode = "";
      }
      loaded.value = true;
    });

    onUpdated(() => {
      let filter =
        route?.query?.status && route?.query?.status != "ALL" ? route.query.status : "";
      if (filter == Filter.CertificateStatusCode) return;
      Filter.CertificateStatusCode = filter; //pass
      table.value.handleCurrentChange(1, Filter);
    });

    let filterAction = async () => {
      await table.value.handleCurrentChange(1, Filter);
    };

    const resetParams = () => {
      let keys = Object.keys(Filter);
      for (let key of keys) {
        if (fieldsToSkip.indexOf(key) > -1) continue;
        Filter[key] = "";
      }
      filterAction();
    };

    const sortChange = ({ prop, order }) => {
      Filter.SortBy = prop;
      Filter.SortOrder = order;
      filterAction();
    };

    const handleEdit = (row, opt) => {
      if (opt?.className?.indexOf("action-col") > -1) return;
      router.changeRoute(row.Type == "CERTIFICATE" ? "/certificate" : "/statement", {
        key: row.CertificateKey,
      });
    };

    const exportCert = ($refs) => {
      let FilterTable = { ...Filter, Filter: $refs?.table?.Filter?.Filter || "" };
      $refs.export.exportContent({
        Mode: "GENERATE_CERTIFICATE_LIST",
        Filter: FilterTable,
        ExcelOnly: true,
      });
    };

    return {
      exportCert,
      handleEdit,
      sortChange,
      loaded,
      Filter,
      table,
      resetParams,
      filterAction,
      StatusOpts,
      ServiceTypeOpts,
      mq,
    };
  },
};
</script>
<style>
.entries-select {
  width: 90px;
}
</style>
